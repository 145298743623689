$(document).ready(function(){
  var Gallery = new SwipeGallery({
    selector: $('.b-gallery'),
    loop: true,
    positionActive: 'center',
    elementsOnSide: 2,
    mouseEvents: true,
    onChange: function(){
      var cur = $('.b-gallery').find('.active').index()
      $('.b-product').eq(cur).addClass('_state_active').siblings().removeClass('_state_active')
    }
  });
  $('.arrow_left').on('click', function(){
    Gallery.prev();
  });
  $('.arrow_right').on('click', function(){
    Gallery.next();
  });
});
