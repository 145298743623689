$(document).ready(function(){

  var slider = $('[data-js="slider"]'),
    sliderItems = $('[data-js="slider-items"]'),
    sliderItem = $('[data-js="slider-item"]');

  var sidebarItems = $('[data-js="sidebar-items"]'),
    sidebarItem = $('[data-js="sidebar-item"]');

  var sliderControls = $('[data-js="slider-controls"]'),
    sliderControl = $('[data-js="slider-control"]');

  var timer;

  function sliderTimer(){

    timer = setTimeout(function(){
      switchSlide();
      switchSidebar();
      switchControl();
      sliderTimer();
    }, 5000);
  };

  sliderTimer();

  sliderControl.on('click', function(){
    if ($(this).hasClass('._state_active')){
      return false;
    }
    $(this)
      .addClass('_state_active')
      .siblings()
      .removeClass('_state_active');
    sliderItem
      .eq($(this).index())
      .addClass('_state_active')
      .siblings()
      .removeClass('_state_active');
    sidebarItem
      .eq($(this).index())
      .addClass('_state_active')
      .siblings()
      .removeClass('_state_active');

    clearTimeout(timer);
    sliderTimer();
  });

  function switchSlide(){
    var current = sliderItems.find('._state_active');
    current.removeClass('_state_active');
    if (current.index() >= sliderItem.length - 1){
      sliderItem.eq(0).addClass('_state_active');
    } else {
      current.next().addClass('_state_active');
    };
  };

  function switchSidebar(){
    var current = sidebarItems.find('._state_active');
    current.removeClass('_state_active');
    if (current.index() >= sidebarItem.length - 1){
      sidebarItem.eq(0).addClass('_state_active');
    } else {
      current.next().addClass('_state_active');
    };
  };

  function switchControl(){
    var current = sliderControls.find('._state_active');
    current.removeClass('_state_active');
    if (current.index() >= sidebarItem.length - 1){
      sliderControl.eq(0).addClass('_state_active');
    } else {
      current.next().addClass('_state_active');
    };
  };


});
